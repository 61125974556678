import { createApp, h } from 'vue';
import Search from './Search.vue';

const desktopMountPoint = document.querySelector('#rpb_search-container');
const mobileMountPoint = document.querySelector('#rpb_main-navbar-search');

if (desktopMountPoint) {
	// eslint-disable-next-line
	const app = createApp({ name: 'Solr-Search', render: () => h(Search) });
	app.mount(desktopMountPoint);
}

if (mobileMountPoint) {
	// eslint-disable-next-line
	const app = createApp({ name: 'Solr-Search', render: () => h(Search) });
	app.mount(mobileMountPoint);
}
