import { createApp } from 'vue';
import { mountReplace } from '@/js/utils/vue-mount-replacer';
import HeaderFavoriteButton from './HeaderFavoriteButton.vue';

const mountPoint = document.querySelector('.header-iconbar__entry-wrapper');

if (mountPoint) {
	const app = createApp(HeaderFavoriteButton);
	mountReplace(app, mountPoint);
}
