import { createApp, h, onBeforeMount } from 'vue';
import { useStore } from '@components/common/store';
import { determineClient, determinePageType } from '@utils/utils';
import { parseSearchMaskAndFilterSettings, ParsedData } from '@components/common/services/transform';
import SearchForm from '@lmt-rpb/SearchForm/SearchForm.vue';
import { EventBus } from '@global-js/event-bus';
import { BackgroundImages } from '@interfaces/search-form';
import { mountReplace } from '@/js/utils/vue-mount-replacer';

const store = useStore();

const pageType = determinePageType();
const headerSearch: HTMLElement | null = document.getElementById('search-form__header');
const mainSearchMountPoint: HTMLElement | null = document.getElementById('search-form__main');

const mainSearchFormContainer = document.getElementById('search-form-container');
let mainSearchInstantiated = false;

const searchmaskSettingsData = (document.querySelector('#search-mask-data') as HTMLElement)?.dataset;
const urlParamsData = (document.querySelector('.url-params-data') as HTMLElement)?.dataset;

// eslint-disable-next-line no-use-before-define
initSearch();

/**
 * Mounts searchmask
 * @param mountPoint HTMLElement - the element for root to mount
 * @param isHeaderSearch boolean - search in the header
 */
function mountSearch(mountPoint: HTMLElement, isHeaderSearch = false) {
	let data: DOMStringMap;
	let parsedDataForUpdateFormData: ParsedData = {};
	const regionInfoSpan = document.getElementById('region-seasons');
	const isAutoGeneratedTopic = document.body.classList.contains('page_topic');
	let images: BackgroundImages;

	if (isHeaderSearch) {
		if (pageType === 'hotelPage') {
			const hotelInfoSpan = document.getElementById('typo-data')?.dataset;
			data = { ...(mountPoint as HTMLElement).dataset, ...hotelInfoSpan };
			data.phoneLink = hotelInfoSpan?.phoneLink;
			data.phone = hotelInfoSpan?.phone;
		} else if (pageType === 'regionPage') {
			data = { ...(mountPoint as HTMLElement).dataset, ...searchmaskSettingsData, ...regionInfoSpan?.dataset };
		} else if (pageType === 'hotelList') {
			const hotelListDataset = document.getElementById('hotel-list-data')?.dataset;
			data = { ...(mountPoint as HTMLElement).dataset, ...hotelListDataset };
			data.phoneLink = hotelListDataset?.phoneLink;
			data.phone = hotelListDataset?.phone;
		} else if (pageType === 'regionList') {
			const regionListDataset = document.getElementById('region-list-data')?.dataset;
			data = { ...(mountPoint as HTMLElement).dataset, ...regionListDataset };
			data.phoneLink = regionListDataset?.phoneLink;
			data.phone = regionListDataset?.phone;
		} else {
			data = { ...(mountPoint as HTMLElement).dataset };
			if (searchmaskSettingsData) {
				parsedDataForUpdateFormData = parseSearchMaskAndFilterSettings(searchmaskSettingsData);
			}
			if (urlParamsData && urlParamsData.urlParams) {
				const parsedUrlParams = parseSearchMaskAndFilterSettings(urlParamsData).urlParams;
				parsedDataForUpdateFormData = { ...parsedDataForUpdateFormData, ...parsedUrlParams };
			}
		}
	} else {
		data = { ...(mountPoint as HTMLElement).dataset };

		if (data.images) {
			images = JSON.parse(data.images);
		}
	}

	if (pageType === 'hotelPage') {
		// Add phone number from mainSearch's dataset to all search masks (header and main)
		data.phone = searchmaskSettingsData?.phone;
		data.phoneLink = searchmaskSettingsData?.phoneLink;
	}

	const Search = createApp({
		name: `SearchForm-${isHeaderSearch ? 'Header' : 'Main'}`,
		setup() {
			onBeforeMount(() => {
				if (isHeaderSearch) {
					if (!store.state.config.storeInitialized) {
						store.dispatch('initStore', data).then(() => {
							EventBus.$emit('search-form:storeInitialized');
						});
					}
					if (parsedDataForUpdateFormData) {
						// Update searchmask store with editorial settings
						store.commit('searchMask/updateFormData', parsedDataForUpdateFormData);
					}
				}
			});
		},
		data: () => (data),
		render: () => h(SearchForm, {
			client: determineClient(document.location.href),
			images,
			isHeaderSearch,
		}),
	});

	mountReplace(Search, mountPoint);

	if (isAutoGeneratedTopic) EventBus.$emit('Autocomplete:updatedActiveItem'); // Init fixed button
}

/**
 * Instantiate the main search
 */
function instantiateMainSearch() {
	if (mainSearchMountPoint) {
		mountSearch(mainSearchMountPoint);
	}

	if (document.body.classList.contains('search-open')) {
		EventBus.$emit('offer-search:toggle', false, true, true);
	}
	mainSearchInstantiated = true;
}

/**
 * Bootstraps the search
 */
function initSearch() {
	if (pageType === 'searchLandingPage') return; // searchLandingPage inits it's own search
	if (document.querySelector('.search-form')) {
		if (headerSearch) {
			mountSearch(headerSearch, true);
		}

		if ((pageType === 'homePage')
		// On theme pages and region pages, instantiate the content flow search on desktop devices
		|| (['themePage', 'regionPage', 'hotelList', 'regionList'].indexOf(pageType) !== -1 && window.innerWidth >= 1300)) {
			if (mainSearchMountPoint) {
				mountSearch(mainSearchMountPoint);
			}

			mainSearchInstantiated = true;
		}

		if (pageType === 'hotelPage') {
			mainSearchInstantiated = true;
		}

		if (['themePage', 'regionPage', 'hotelList', 'hotelPage', 'regionList'].indexOf(pageType) !== -1 && !mainSearchInstantiated) {
			instantiateMainSearch();
			mainSearchFormContainer?.classList.add('search-form-container--hide-on-mobile');
		}
	}
}
