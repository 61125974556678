<template>
	<div>
		<OfferGrid
			v-if="!loading && !error && items.length"
			:hotel-list="items"
			:slider="isSlider"
			:currency-symbol="currencySymbol"
			:show-suns="showSuns"
			:show-bottom-c-t-a="false"
			:destination-name="destinationName"
			:header-props="headerPropsClone"
			:more-link="moreLinkClone"
			:four-columns="fourColumns"
		/>
		<div
			v-if="loading && !error"
			class="loading__headlines"
		>
			<SkeletonLoader
				:width-unit="'rem'"
				:loader-width="30"
				:loader-height="7"
				:border-radius="'0.5rem'"
				class="loading__headline"
			/>
			<SkeletonLoader
				:width-unit="'rem'"
				:loader-width="16"
				:loader-height="3"
				:border-radius="'0.5rem'"
				class="loading__more-link"
			/>
		</div>
		<div
			v-if="loading && !error"
			class="loading__container"
		>
			<div
				v-for="index in hotelBoxAmount"
				:key="index"
				class="loading__item"
			>
				<SkeletonLoader
					:width-unit="'%'"
					:loader-width="100"
					:loader-height="44.6"
					:border-radius="'1.2rem'"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import OfferGrid from '@lmt-rpb/OfferGrid/OfferGrid.vue';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import axios, { AxiosError } from 'axios';
import request from '@components/common/services/request';
import { determineClient, getLocaleString } from '@utils/utils';
import { HotelBoxData } from '@/interfaces/components/hotelBoxData';
import { ApiDataTypes } from '@components/common/types/index';
import { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import { EventBus } from '@global-js/event-bus';
import { BestPackageOfferForHotelListResponse } from '@/interfaces/api/v1-best-package-offer-for-hotel-list';
import { useStore } from '@/components/common/store';
import { computed, onMounted, ref } from 'vue';
import { transformList, buildListUrl } from './hotelGridHelper';

const store = useStore();

interface Props {
	fromPlugin: boolean,
	hotelGridOffers: HotelBoxData[],
	bodyParams: ApiDataTypes,
	hotelOnly: boolean,
	apiUrl: string,
	uniqueKey: string,
	destinationName?: string,
	moreLink: string,
	headerProps: HeaderProps,
	hotelBoxAmount?: number,
	fourColumns?: boolean,
	isAutoGeneratedTopic?: boolean,
	queryString?: string,
	isSlider?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	destinationName: '',
	hotelBoxAmount: 3,
	fourColumns: false,
	isAutoGeneratedTopic: false,
	queryString: '',
	isSlider: true,
});

const headerPropsClone = ref<HeaderProps>(props.headerProps);

const moreLinkClone = ref<string>(props.moreLink);

const items = ref<HotelBoxData[] | never[]>([]);

const error = ref(false);

const loading = ref(true);

const currencySymbol = ref(getLocaleString('currency'));

const showSuns = ref(determineClient(window.location.href) === 'com');

const destinationType = computed((): string => {
	const destinationTypes = {
		page_country: 'country',
		page_region: 'region',
		page_city: 'city',
		page_topic_regiongroup: 'country',
		page_topic_topregion: 'region',
	};
	const bodyClassList = document.body.classList;
	let type = '';

	Object.keys(destinationTypes).forEach((pageClass) => {
		if (bodyClassList.contains(pageClass)) {
			type = destinationTypes[pageClass as keyof typeof destinationTypes];
		}
	});

	return type;
});

const fetchOffers = (): void => {
	loading.value = true;
	error.value = false;
	request<BestPackageOfferForHotelListResponse>({ method: 'post', url: props.apiUrl, data: props.bodyParams }, props.uniqueKey)
		.then((resp) => {
			if (!resp || Object.keys(resp).length === 0) {
				return;
			}
			items.value = transformList(resp.Offers, props.hotelOnly, props.bodyParams, false);
		})
		.catch((axiosError: AxiosError) => {
			if (!axios.isCancel(axiosError)) {
				console.error('HotelGrid: ', axiosError);
			}

			error.value = true;
		})
		.finally(() => {
			loading.value = false;
		});
};

const updateHeaderPropsClone = (): void => {
	let newListUrl = buildListUrl(
		false, // Don't append query string on auto generated 'Urlaub' or 'Reiseziel' pages
		store.state.searchMask?.destination?.id as number,
		destinationType.value,
		props.hotelOnly
	);
	const params = props.queryString ? `?${props.queryString}` : '';
	if (props.isAutoGeneratedTopic) newListUrl = `${newListUrl}${params}`;

	headerPropsClone.value = {
		...props.headerProps,
		link: newListUrl
	};
	moreLinkClone.value = newListUrl;
};

onMounted((): void => {
	if (props.fromPlugin) {
		// hotelgrid offers from plugin already transformed
		items.value = props.hotelGridOffers;
		loading.value = false;

		// Append url search parameter on topic pages
		if (props.isAutoGeneratedTopic) {
			updateHeaderPropsClone();
		}
	} else {
		const destinationId = store?.state?.searchMask?.destination?.id;
		if (!destinationId) {
			EventBus.$once('Autocomplete:updatedActiveItem', updateHeaderPropsClone);
		} else {
			updateHeaderPropsClone();
		}

		fetchOffers();
	}
});

</script>
<style lang="scss">
.rpb_topoffers.rpb_topoffers--no-border-top > .rpb_topoffers__row > .rpb_topoffers__headline h2 {
	padding-top: 0;
}
</style>

<style lang="scss" scoped>
/* stylelint-disable-next-line selector-id-pattern */
:deep(#rpb_horizontal-grid.rpb_container) {
	margin-top: 0;
	margin-bottom: 0;
}

.loading {
	&__container {
		display: flex;
		gap: 0;
		width: 100%;
		padding: 1rem 2rem 1rem 1rem;
		margin: 3rem 0 $vgrid-mobile-large 0;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		justify-content: initial;
		padding-left: 2.66%;
		padding-right: 2.66%;
	}

	&__headlines {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-left: 2.66%;
		padding-right: 2.66%;
		margin-left: 0.7rem;
	}

	&__more-link {
		display: none;
		margin-right: 1rem;
		margin-top: 1rem;
	}

	&__item {
		min-height: $grid-item-min-height;
		width: 31rem;
		min-width: 28rem;
		height: 41.8rem;
		float: none;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		margin: 0 0.5rem 0 0.5rem;
		flex: 1 0 auto;
	}
}

@media (min-width: $breakpoint-verysmall) {
	.loading {
		&__more-link {
			display: block;
		}
	}
}

@media (min-width: $breakpoint-scroller + 1px) {
	.loading {
		&__container {
			display: grid;
			grid-template-columns: repeat(auto-fill, 32%);
			max-width: $breakpoint-container;
			margin: 4rem 0 $vgrid-desktop-large 0;
			overflow: auto;
			padding: 0 2.66%;
			gap: 1.5rem;

		}

		&__more-link {
			margin-right: 1.5rem;
		}

		&__item {
			width: 100%;
			height: auto;
			margin: 0;
		}
	}
}

@media (min-width: $breakpoint-verylarge) {
	.loading {
		&__container {
			padding-left: 0;
			padding-right: 0;

		}
		&__headlines {
			padding-left: 0;
		}
		&__more-link {
			margin-right: 0;
		}
	}
}

</style>
