import { createApp } from 'vue';
import { mountReplace } from '@/js/utils/vue-mount-replacer';
import TrustpilotContainer from './TrustpilotContainer.vue';

const trustpilotSection: HTMLElement | null = document.querySelector('.rpb_trustpilot');

if (trustpilotSection) {
	const trustpilotUrl = trustpilotSection.dataset.trustpilotUrl;
	const minStars = trustpilotSection.dataset.minStars;
	const limit = trustpilotSection.dataset.limit;

	const app = createApp(TrustpilotContainer, {
		baseURL: window.baseURL,
		trustpilotUrl,
		limit,
		minStars,
	});

	mountReplace(app, trustpilotSection);
}
