<template>
	<div>
		<OfferGrid
			v-if="!loading && !error && items.length"
			:hotel-list="items"
			:deal="true"
			:slider="isSlider"
			:currency-symbol="currencySymbol"
			:show-suns="showSuns"
			:header-props="headerProps"
			:show-bottom-c-t-a="false"
			:more-link="moreLink"
			:destination-name="destinationName"
			:four-columns="isFourColumns"
		/>
		<div
			v-if="loading && !error"
			class="loading__headlines"
		>
			<SkeletonLoader
				:width-unit="'rem'"
				:loader-width="30"
				:loader-height="7"
				:border-radius="'0.5rem'"
				class="loading__headline"
			/>
		</div>
		<div
			v-if="loading && !error"
			:class="skeletonType === 'grid' ? 'loading__container-deal-list' : 'loading__container'"
		>
			<div
				v-for="index in numberOfSkeletons"
				:key="index"
				:class="skeletonType === 'grid' ? 'loading__item-deal-list' : 'loading__item'"
			>
				<SkeletonLoader
					:width-unit="'%'"
					:loader-width="100"
					:loader-height="skeletonType === 'slider' ? 44.6 : 38"
					:border-radius="'0.5rem'"
				/>
			</div>
		</div>
		<div
			v-if="moreDealsLoading && !error"
			:class="skeletonType === 'grid' ? 'loading__container-deal-list' : 'loading__container'"
		>
			<div
				v-for="index in numberOfSkeletons"
				:key="index"
				:class="skeletonType === 'grid' ? 'loading__item-deal-list' : 'loading__item'"
			>
				<SkeletonLoader
					:width-unit="'%'"
					:loader-width="100"
					:loader-height="skeletonType === 'slider' ? 44.6 : 38"
					:border-radius="'0.5rem'"
				/>
			</div>
		</div>
		<div
			v-if="!isSlider"
			class="deal-list__pager"
			:class="{'deal-list__pager--no-next': !showMoreButton}"
		>
			<rounded-button
				v-if="showMoreButton"
				class="deal-list__more-button"
				@click.prevent="() => getMoreDeals()"
			>
				Mehr Angebote laden
			</rounded-button>
		</div>
	</div>
</template>

<script lang="ts" setup>
import OfferGrid from '@lmt-rpb/OfferGrid/OfferGrid.vue';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import axios, { AxiosError } from 'axios';
import request from '@components/common/services/request';
import { determineClient, getLocaleString } from '@utils/utils';
import { HotelBoxData } from '@/interfaces/components/hotelBoxData';
import { HotelDeal, HotelDealsResponse } from '@/interfaces/api/v1-hotel-deals';
import { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import {
	computed, onMounted, Ref, ref,
} from 'vue';
import { transformList } from './helper';

interface Props {
	fromPlugin: boolean,
	isSlider: boolean,
	isFourColumns: boolean,
	apiUrl: string,
	uniqueKey: string,
	destinationName: string,
	moreLink: string,
	headerProps: HeaderProps,
	parentGrid: HTMLElement,
	hotelBoxAmount: string,
}

const props = withDefaults(defineProps<Props>(), {
	hotelBoxAmount: '',
	destinationName: '',
	moreLink: '',
});

const items : Ref<HotelBoxData[] | never[]> = ref([]);

const mqScroller: Ref<MediaQueryList | undefined> = ref();

const error = ref(false);

const loading = ref(true);

const moreDealsLoading = ref(false);

const skeletonType: Ref<'grid' | 'slider'> = ref('slider');

const currencySymbol = ref(getLocaleString('currency'));

const showSuns = ref(determineClient(window.location.href) === 'com');

const links: Ref<{ prev?: string; next?: string }> = ref({});

const numberOfSkeletons = computed((): number => parseInt(props.hotelBoxAmount, 10) || 3);

const showMoreButton = computed((): boolean => {
	if (links.value && links.value.next) {
		return true;
	}
	return false;
});

const checkIsScroller = (media: MediaQueryListEvent | MediaQueryList): void => {
	if ((props.isSlider && !media.matches) || !props.isSlider) {
		skeletonType.value = 'grid';
	} else {
		skeletonType.value = 'slider';
	}
};

const fetchOffers = (): void => {
	loading.value = true;
	error.value = false;

	request<HotelDealsResponse>({ method: 'get', url: props.apiUrl, }, props.uniqueKey)
		.then((resp) => {
			if (!resp?.Offers?.length) {
				props.parentGrid.remove(); // removing parent grid to remove whitespace due to min-height set on grid
				return;
			}

			items.value = transformList(resp.Offers as unknown as HotelDeal[], 'v1-hotel-deals');

			if (!props.isSlider) {
				links.value = resp?._links || {};
			}
		})
		.catch((axiosError: AxiosError) => {
			if (!axios.isCancel(axiosError)) {
				console.error('DealGrid: ', axiosError);
			}

			error.value = true;
		})
		.finally(() => {
			loading.value = false;
		});
};

const getMoreDeals = (): void => {
	if (props.isSlider) {
		return;
	}

	moreDealsLoading.value = true;
	error.value = false;

	request<HotelDealsResponse>({ method: 'get', url: links.value.next, }, props.uniqueKey)
		.then((resp) => {
			const newItems = transformList(resp.Offers as unknown as HotelDeal[], 'v1-hotel-deals');
			items.value = [...items.value, ...newItems];
			links.value = resp._links || {};
		})
		.catch((axiosError: AxiosError) => {
			if (!axios.isCancel(axiosError)) {
				console.error('DealGrid: ', axiosError);
			}

			error.value = true;
		})
		.finally(() => {
			moreDealsLoading.value = false;
		});
};

onMounted(() => {
	mqScroller.value = window.matchMedia('(max-width: 1024px)');
	mqScroller.value.addListener(checkIsScroller);

	fetchOffers();
	checkIsScroller(mqScroller.value);
});

</script>
<style lang="scss">
.rpb_topoffers.rpb_topoffers--no-border-top > .rpb_topoffers__row > .rpb_topoffers__headline h2 {
	padding-top: 0;
}
</style>

<style lang="scss" scoped>
/* stylelint-disable-next-line selector-id-pattern */
:deep(#rpb_horizontal-grid.rpb_container) {
	margin-top: 0;
	margin-bottom: 0;
}

:deep(.rpb_hotelbox.rpb_hotelbox--deal-list) {
	overflow: hidden;
}

.deal-list__pager {
	text-align: center;
	margin-top: $vgrid-mobile-small;
	margin-bottom: 5.4rem;
}

.loading {
	&__container {
		display: flex;
		margin: 4rem auto $vgrid-mobile-large;
		max-width: $breakpoint-container;
		overflow-x: scroll;
		padding-left: 2.66%;
		padding-right: 2.66%;
	}

	&__container-deal-list {
		display: grid;
		max-width: $breakpoint-container;
		overflow: auto;
		justify-content: center;
		justify-items: center;
		flex-wrap: wrap;
		grid-template-columns: 1fr;
		font-size: 0;
		gap: 1.5rem;
		margin-top: 3rem;
	}

	&__headlines {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-left: 2.66%;
		padding-right: 2.66%;
		margin-left: 0.7rem;
	}

	&__item {
		width: 33%;
		min-width: 28rem;
		margin: 0 1rem;
	}

	&__item-deal-list {
		margin: 0 1rem;
		padding: 0 2.66%;
		width: 100%;
		max-width: 40rem;
		max-height: 53.3rem;
	}
}

@media screen and (min-width: $breakpoint-mobiletabs) {
	.loading__item-deal-list {
		padding: 0;
	}
}

@media (min-width: $breakpoint-small) {
	.loading {
		&__container-deal-list {
			grid-template-columns: repeat(auto-fill, 46%);
		}
		&__item-deal-list {
		max-width: none;
		}
	}
}

@media (min-width: $breakpoint-medium) {
	.loading {
		&__container-deal-list {
			grid-template-columns: repeat(auto-fill, 32%);
			padding-left: 2.66%;
			padding-right: 2.66%;
		}
		&__headlines {
			margin-left: 1rem;
		}
		&__headline {
			margin-left: 0;
		}
	}
}

@media (min-width: $breakpoint-verylarge) {
	.loading {
		&__container-deal-list {
			grid-template-columns: repeat(auto-fill, 32%);
			padding-left: 0;
			padding-right: 0;
		}

		&__headlines{
			padding-left: 0;
			padding-right: 0;
		}
		&__container {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

@media (min-width: $breakpoint-scroller + 1px) {
	.loading {
		&__container {
			overflow-x: auto;
			margin-bottom: $vgrid-desktop-large;
		}

		&__container-deal-list {
			margin-top: 4rem;

		}
	}

	.deal-list__pager {
		margin-top: $vgrid-desktop-small;
	}
}

</style>
