import { createApp } from 'vue';
import { mountReplace } from '@/js/utils/vue-mount-replacer';
import NewsletterContainer from './NewsletterContainer.vue';

const newsletterLink = document.querySelector('.footer__newsletter a');

if (newsletterLink) {
	newsletterLink.addEventListener('click', (e) => {
		e.preventDefault();
		const mountPoint = document.querySelector('.rpb_newsletter');

		if (!document.querySelector('.rpb_newsletter--fadein') && mountPoint) {
			const app = createApp(NewsletterContainer, {
				show: true,
			});
			mountReplace(app, mountPoint);
		}
	});
}
