import { SuggestionElements } from '@/interfaces/api/v1-search-suggest';
import axios from 'axios';
import {
	getBaseUrl,
} from '@utils/utils';

export const get = async(suggest: string): Promise<SuggestionElements[]> => {
	const encodedTerm = encodeURIComponent(suggest);
	const url = `${getBaseUrl()}/index.php?eID=rpb_suggest&q=${encodedTerm}`;
	try {
		const response = await axios({
			method: 'GET',
			url,
			headers: {
				'content-type': 'application/json'
			},
		});
		return response.data.all.elements ? response.data.all.elements : [];
	} catch (error) {
		console.error(error);
		throw new Error('An error occurred while executing the Solr search');
	}
};
