import { createApp } from 'vue';
import { getFluxApiUrl } from '@utils/utils';
import { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import { mountReplace } from '@/js/utils/vue-mount-replacer';
import DealGrid from './DealGrid.vue';
/**
 * Build url params
 * @param DOMStringMap params
 */
const buildUrlParams = (params: DOMStringMap): string => {
	let urlParamString = '',
		separator = '',
		counter = 0;

	Object.keys(params).forEach(function(key: string) {
		if ((parseInt(params[key] as string, 10) === 0) || (!params[key])) {
			return false;
		}

		separator = counter === 0 ? '?' : '&';
		urlParamString += separator + key + '=' + encodeURIComponent(params[key] as string);
		counter++;
		return true;
	});
	return urlParamString;
};

/**
 * Init function for the DealGrid component
 */
const initDealGrid = (selector: string): void => {
	const dealSection = document.querySelector(selector) as HTMLElement;
	const hotelGridData: DOMStringMap = (dealSection?.querySelector('.rpb_hotelgrid--data') as HTMLElement)?.dataset;
	const parentGrid = dealSection.parentNode;
	if (!hotelGridData) {
		// eslint-disable-next-line no-console
		console.log('DealGrid: Keine Daten für Request verfügbar.');
		return;
	}

	const fromPlugin = !!hotelGridData.from_plugin;
	const isSlider = !fromPlugin ? true : dealSection.dataset.slider === 'true';
	const isFourColumns = dealSection.dataset.fourColumns === 'true';
	const hotelBoxAmount = hotelGridData?.limit;
	let apiUrl = '';
	let destinationName: string | undefined;

	if (hotelGridData.region_group_id) {
		const regionGroupId = hotelGridData.region_group_id;
		destinationName = hotelGridData.region_group_name;
		apiUrl = getFluxApiUrl() + '/v1/hotel-deals/region-group/' + regionGroupId;
	} else if (hotelGridData.region_id) {
		const regionId = hotelGridData.region_id;
		destinationName = hotelGridData.region_name;
		apiUrl = getFluxApiUrl() + '/v1/hotel-deals/region/' + regionId;
	} else {
		apiUrl = getFluxApiUrl() + '/v1/hotel-deals';
	}

	const gridFlexformValues = (dealSection.parentNode as HTMLElement)?.dataset;

	const moreLink = gridFlexformValues.moreLink;

	const headerProps: HeaderProps = {
		title: fromPlugin && gridFlexformValues.header ? gridFlexformValues.header : `Restplätze ${destinationName}`,
		subtitle: fromPlugin ? gridFlexformValues.subheader : 'Last Minute Angebote mit der größten Ersparnis',
		mobileTitle: fromPlugin && gridFlexformValues.headerMobile ? gridFlexformValues.headerMobile : `Restplätze ${destinationName}`,
		mobileSubtitle: fromPlugin ? gridFlexformValues.subheaderMobile : 'Last Minute Angebote mit der größten Ersparnis',
		moreText: gridFlexformValues.linkText || 'Mehr',
		link: moreLink,
		titleAttribute: fromPlugin ? (gridFlexformValues.headerMobile || gridFlexformValues.header) : '',
	};

	// Removing unecessary keys before creating params
	delete hotelGridData.from_plugin;
	delete hotelGridData.region_id;
	delete hotelGridData.region_group_id;
	delete hotelGridData.region_group_name;
	delete hotelGridData.region_name;
	delete hotelGridData.isSlider;
	apiUrl += buildUrlParams(hotelGridData);

	const app = createApp(DealGrid, {
		fromPlugin,
		apiUrl,
		uniqueKey: selector,
		destinationName,
		headerProps,
		moreLink,
		parentGrid,
		isSlider,
		hotelBoxAmount,
		isFourColumns
	});

	mountReplace(app, dealSection);
};

const intersectionCallback = (entries: IntersectionObserverEntry[]): void => {
	if (entries[0].isIntersecting) {
		initDealGrid('.' + entries[0].target.classList[2]);
	}
};

const createDealGridObserver = (selector: string): void => {
	const dealGridObserver = new IntersectionObserver(intersectionCallback, {
		root: null,
		rootMargin: '200px',
	});
	const dealGridTarget = document.querySelector(selector);
	if (dealGridTarget) {
		dealGridObserver.observe(dealGridTarget);
	}
};

(function() {
	const dealsSections = document.querySelectorAll('.rpb_hotelgrid--deals');
	if (!dealsSections.length) {
		return;
	}

	// Array for multiple instances of vueDeals
	const vueSelectorList: string[] = [];
	for (let i = 0; i < dealsSections.length; i++) {
		const vueSelector = 'rpb_hotelgrid--deals--' + i;
		dealsSections[i].classList.add(vueSelector);
		vueSelectorList[i] = '.' + vueSelector;
	}

	vueSelectorList.forEach((selector) => {
		const dealSection = document.querySelector(selector) as HTMLElement;
		const hotelGridData: DOMStringMap = (dealSection?.querySelector('.rpb_hotelgrid--data') as HTMLElement)?.dataset;
		const fromPlugin = !!hotelGridData.from_plugin;

		if (!fromPlugin) {
			initDealGrid(selector);
		} else {
			createDealGridObserver(selector);
		}
	});
}());
