<template>
	<div
		class="banner"
		:class="{ 'banner--hidden': !showBanner }"
	>
		<div class="banner__inner">
			<a
				class="banner__info-block"
				:href="link"
				rel="noopener"
				:title="text"
			>
				<base-icon
					name="infoFill"
					class="banner__info-icon"
				/>
				<span class="banner__text">{{ text }}</span>
				<span class="banner__subtext">{{ subtext }}</span>
			</a>
			<button
				class="banner__button"
				:title="localeString"
				@click.stop.prevent="closeBanner"
			>
				<base-icon
					name="close"
					class="banner__close-icon"
				/>
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { getLocaleString } from '@utils/utils';
import { EventBus } from '@global-js/event-bus';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	text: string,
	subtext: string,
	link: string,
	show?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	show: false
});

const showBanner = ref(props.show);
const localeString = getLocaleString('close');
const emit = defineEmits(['BannerTop:Close']);

const closeBanner = () => {
	emit('BannerTop:Close');
	showBanner.value = false;
	EventBus.$emit('BannerTop:Close');
};
</script>

<style lang="scss" scoped>
.banner {
	display: flex;
	background-color: $color-vivid;
	height: 4.8rem;
	opacity: 1;

	&:focus {
		outline: none;
	}

	&--hidden,
	&--hidden * {
		height: 0;
		opacity: 0;
		transition: all 0.2s ease-out;
		transition-property: height, opacity;
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;
		width: 100%;
		max-width: $breakpoint-container;
	}

	&__info-block {
		display: flex;
		align-items: center;
		text-decoration: none;
		padding-left: 1.2rem;

		&:hover {
			text-decoration: none;
		}
	}

	&__info-icon,
	&__close-icon {
		width: 2rem;
		opacity: 1;
		fill: $color-link;
	}

	&__close-icon {
		vertical-align: bottom;
		fill: $color-primary;
	}

	&__text {
		font-size: 1.1rem;
		font-weight: $bold-default;
		font-family: $font-family-special;
		color: $color-primary;
		margin-left: 1rem;
		margin-right: 0.6rem;
	}

	&__subtext {
		font-size: 1rem;
		color: $color-text-light;
		font-style: italic;
	}

	&__button {
		border: none;
		background-color: transparent;
		padding: 1.2rem 1.2rem;
		cursor: pointer;
		opacity: 1;

		&:focus {
			outline: none;
		}
	}
}

@include media-query-up($breakpoint-mobilelarge) {
	.banner {
		&__text {
			font-size: $font-very-small;
		}
	}
}

@include media-query-up($breakpoint-small) {
	.banner {
		&__text {
			font-size: $font-medium;
		}

		&__subtext {
			font-size: 1.2rem;
		}
	}
}

@include media-query-up($breakpoint-container + 20px) {
	.banner {
		&__info-block {
			padding-left: 0;
		}
	}
}

</style>
