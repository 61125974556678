import { createApp } from 'vue';
import PillList from '@lmt-rpb/PillList/PillList.vue';
import { mountReplace } from '../utils/vue-mount-replacer';

interface PillsData {
	label: string;
	url: string;
	title: string
}

const mountPoint = document.getElementById('quick-pill-list');
const dataset = mountPoint?.dataset;
let parsedJSON: PillsData[];

const initPillList = () => {
	const app = createApp(PillList, {
		pills: parsedJSON,
	});
	mountReplace(app, mountPoint!);
};

if (dataset?.pillsList) {
	parsedJSON = JSON.parse(dataset.pillsList);
	initPillList();
}
