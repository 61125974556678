<template>
	<section
		ref="teaserElement"
		class="teaser"
	>
		<ComponentHeader
			:header-props="headerProps"
		/>
		<Carousel
			ref="scroll"
			v-bind="carouselSettings"
			class="teaser__scroll"
			:class="[{'teaser__scroll--left': showLeftOverlay}, {'teaser__scroll--right': showRightOverlay}]"
		>
			<Slide
				v-for="(item, index) in items"
				:key="index"
				v-observe-visibility="{
					callback: visibilityChanged,
					intersection: {
						threshold: 1,
					},
				}"
				:index="index"
				:class="[{'teaser__first-element': index == 0}, {'teaser__last-element': index == items.length - 1}]"
				:title="item.text"
			>
				<a
					:href="isDragging ? undefined : item.link"
					class="teaser__item"
				>
					<img
						class="teaser__item-image rpb_lazy"
						data-sizes="auto"
						:data-src="item.image"
						src="data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw=="
						:alt="item.text"
						@mousedown="onMouseDown"
						@mouseup="onMouseUp"
						@mousemove="onMouseMove"
					/>
					<div class="teaser__item-footer">
						<span class="teaser__item-name">{{ item.text }}</span>
						<span
							v-if="item.price"
							class="teaser__item-price"
						>
							<small>ab </small>
							<span class="teaser__item-price-formatted">{{ formatPrice(item.price, currency) }}</span>
						</span>
					</div>
				</a>
			</Slide>
			<template #addons>
				<CarouselNavigation>
					<template #prev>
						<span class="teaser__arai-label">prev</span>
						<BaseIcon
							name="chevron"
							class="teaser__prev-icon"
						/>
					</template>
					<template #next>
						<span class="teaser__arai-label">next</span>
						<BaseIcon
							name="chevron"
							class="teaser__next-icon"
						/>
					</template>
				</CarouselNavigation>
			</template>
		</Carousel>
	</section>
</template>

<script lang="ts" setup>
import {
	Carousel, Slide, Navigation as CarouselNavigation,
} from 'vue3-carousel';
import { ObserveVisibility as vObserveVisibility } from 'vue-observe-visibility';
import { addTopMarginToFirstElement } from '@utils/utils';
import { formatPrice } from '@/js/utils/priceUtils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import { onMounted, onUpdated, ref } from 'vue';

interface TeaserData {
	text: string;
	link: string;
	price: number;
	image: string;
}

interface HeaderProps {
	title: string;
	subtitle?: string;
	mobileTitle: string;
	mobileSubtitle?: string;
	moreText?: string;
	titleAttribute?: string;
	link?: string;
}

interface Props {
	headerProps: HeaderProps,
	items: TeaserData[]
	currency?: string;
}

withDefaults(defineProps<Props>(), {
	currency: 'EUR'
});

const teaserElement = ref<HTMLElement | null>(null);

const scroll = ref<typeof Carousel>();

const showLeftOverlay = ref(false);

const showRightOverlay = ref(true);

const isMouseDown = ref(false);

const isDragging = ref(false);

const carouselSettings = {
	itemsToShow: 1.25,
	itemsToScroll: 1,
	breakpoints: {
		544: {
			itemsToShow: 2.25,
			itemsToScroll: 1,
		},
		768: { // breakpoint-small
			itemsToShow: 3,
			itemsToScroll: 1,
		},
		1200: { // breakpoint-large
			itemsToShow: 4,
			itemsToScroll: 1.25,
			touchDrag: false,
		}
	}
};

const addMarginTop = (): void => {
	if (teaserElement.value) {
		addTopMarginToFirstElement(teaserElement.value);
	}
};

const onMouseDown = (): void => {
	isMouseDown.value = true;
};

const onMouseUp = (e: MouseEvent): void => {
	setTimeout(() => {
		e.preventDefault();
		e.stopPropagation();

		isMouseDown.value = false;
		isDragging.value = false;
	}, 300); // click tilt time
};

const onMouseMove = (): void => {
	if (!isMouseDown.value || isDragging.value) {
		return;
	}

	isDragging.value = true;
};

const visibilityChanged = (isVisible: boolean, entry: IntersectionObserverEntry): void => {
	if (entry.target.className.includes('teaser__first-element')) {
		showLeftOverlay.value = !isVisible;
	}

	if (entry.target.className.includes('teaser__last-element')) {
		showRightOverlay.value = !isVisible;
	}
};

onMounted(() => {
	// TODO: not working correctly because the search renders after this and then there is a layout shift
	addMarginTop();
});
onUpdated(() => {
	scroll.value?.updateSlideWidth();
});

</script>

<style lang="scss" scoped>
@import '~vue3-carousel/dist/carousel.css';

.carousel {
	height: 100%;

	.carousel__slide {
		position: relative;
		cursor: pointer;
		padding-right: 5px;
		height: 100%;
		width: auto;
		display: inline-block;
	}

	:deep(.carousel__prev),
	:deep(.carousel__next) {
		@include visible-from($breakpoint-small);

		width: 5rem;
		height: 5rem;
		background: $color-white;
		border-radius: 100%;
		border: 0.1rem solid $color-primary-l4;
		z-index: 5;
	}

	:deep(.carousel__prev--disabled),
	:deep(.carousel__next--disabled) {
		display: none;
	}

	:deep(.carousel__prev) {
		transform: translate3d(1.5rem, -80%, 0);
	}

	:deep(.carousel__next) {
		transform: translate3d(-1.5rem, -80%, 0);
	}

	@include media-query-up($breakpoint-verylarge) {
		:deep(.carousel__prev) {
			transform: translate3d(-2rem, -80%, 0);
		}

		:deep(.carousel__next) {
			transform: translate3d(1.7rem, -80%, 0);
		}
	}
}

.teaser {
	max-width: 144rem;
	margin: 0 auto $vgrid-mobile-large;

	&--margin-top {
		margin-top: $vgrid-mobile-first-element-top-margin;
	}

	.teaser__scroll {
		position: relative;
		margin-top: 1.2rem;

		&--left::before,
		&--right::after {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			bottom: 0;
			width: 2.3rem;
		}

		&--left::before {
			left: 0;
			background: linear-gradient(90deg, $color-white 0%, $color-white-t0 100%);
		}

		&--right::after {
			right: 0;
			background: linear-gradient(90deg, $color-white-t0 0%, $color-white 100%);
		}
	}

	.teaser__item {
		display: flex;
		flex-direction: column;
		margin: 0.5rem;
		border-radius: 1.5rem;
		box-shadow: -0.2rem 0.2rem 0 0 $color-lime-light;

		&:hover {
			text-decoration: none;
		}
	}

	.teaser__item-image {
		width: 100%;
		height: 100%;
		border-top-left-radius: 1.5rem;
		border-top-right-radius: 1.5rem;
		object-fit: cover;
	}

	.teaser__item-footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		color: $color-primary;
		font-size: $font-small;
		font-weight: $bold-default;
	}

	.teaser__item-name {
		font-size: $font-medium;
	}

	.teaser__item-price {
		@include media-query-up($breakpoint-small) {
			:deep(small) {
				font-size: $font-small;
			}
		}

		color: $color-extra;
		font-size: $font-medium;
		text-align: right;
		white-space: nowrap;

		:deep(small) {
			font-size: 1.2rem;
		}
	}

	.teaser__arai-label {
		@include sr-only;
	}

	.teaser__prev-icon,
	.teaser__next-icon {
		width: 2rem;
		height: 2rem;
		fill: $color-primary;

		&.is-thumb {
			width: 2rem;
			height: 2rem;
		}
	}

	.teaser__prev-icon {
		transform: rotate(-90deg);
	}

	.teaser__next-icon {
		transform: rotate(90deg);
	}

	@include media-query-up($breakpoint-small) {
		margin-bottom: $vgrid-desktop-large;

		&--margin-top {
			margin-top: $vgrid-desktop-first-element-top-margin;
		}
	}
}
</style>
